import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["report_period", "sales_directors", "partners",
    "partner_sources", "partners_statuses",
    "deal_statuses", "sale_stages", "countries",
    "details_table", "deals_count_table", "deals_partners_table",
    "deals_new_partners_table", "lost_deals_table", "user_deals_table",
    "country_deals_table", "partner_deals_table"
  ]

   connect() {
    // Main table
    if (this.hasDetails_tableTarget) {
      const content_url = this.details_tableTarget.getAttribute("data-table-load-url");
      this.createDetailsTable(content_url);
    }

    if (this.hasDeals_count_tableTarget) {
      const content_url = this.deals_count_tableTarget.getAttribute("data-table-load-url");
      this.createDealsCountTable(content_url);
    }

    if (this.hasDeals_partners_tableTarget) {
      const content_url = this.deals_partners_tableTarget.getAttribute("data-table-load-url");
      this.createDealsPartnersTable(content_url);
    }

    if (this.hasDeals_new_partners_tableTarget) {
      const content_url = this.deals_new_partners_tableTarget.getAttribute("data-table-load-url");
      this.createDealsNewPartnersTable(content_url);
    }

    if (this.hasLost_deals_tableTarget) {
      const content_url = this.lost_deals_tableTarget.getAttribute("data-table-load-url");
      this.createLostDealsTable(content_url);
    }

    if (this.hasUser_deals_tableTarget) {
      const content_url = this.user_deals_tableTarget.getAttribute("data-table-load-url");
      this.createUserDealsTable(content_url);
    }

    if (this.hasCountry_deals_tableTarget) {
      const content_url = this.country_deals_tableTarget.getAttribute("data-table-load-url");
      this.createCountryDealsTable(content_url);
    }

    if (this.hasPartner_deals_tableTarget) {
      const content_url = this.partner_deals_tableTarget.getAttribute("data-table-load-url");
      this.createPartnerDealsTable(content_url);
    }
  }

  filterTable() {
    this.setFilterToTable();
  }


  // Main table filters
  setFilterToTable() {
    let filters = []

    if (this.hasReport_periodTarget) {
      filters.push({
        field: "report_period",
        type: "=",
        value: this.report_periodTarget.value
      })
    }

    if (this.hasSales_directorsTarget) {
      filters.push({
        field: "sales_directors_ids",
        type: "=",
        value: this.sales_directorsTarget.choices.getValue(true)
      })
    }

    if (this.hasPartnersTarget) {
      filters.push({
        field: "partners_ids",
        type: "=",
        value: this.partnersTarget.choices.getValue(true)
      })
    }

    if (this.hasPartner_sourcesTarget) {
      filters.push({
        field: "partner_sources_ids",
        type: "=",
        value: this.partner_sourcesTarget.choices.getValue(true)
      })
    }

    if (this.hasPartners_statusesTarget) {
      filters.push({
        field: "partners_statuses",
        type: "=",
        value: this.partners_statusesTarget.choices.getValue(true)
      })
    }

    if (this.hasDeal_statusesTarget) {
      filters.push({
        field: "deal_statuses",
        type: "=",
        value: this.deal_statusesTarget.choices.getValue(true)
      })
    }

    if (this.hasSale_stagesTarget) {
      filters.push({
        field: "sale_stages_ids",
        type: "=",
        value: this.sale_stagesTarget.choices.getValue(true)
      })
    }

    if (this.hasCountriesTarget) {
      filters.push({
        field: "countries_ids",
        type: "=",
        value: this.countriesTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      this.deals_count_tableTarget.tabulator.setFilter(filters);
      this.details_tableTarget.tabulator.setFilter(filters);
      this.deals_partners_tableTarget.tabulator.setFilter(filters);
      this.deals_new_partners_tableTarget.tabulator.setFilter(filters);
      this.lost_deals_tableTarget.tabulator.setFilter(filters);
      this.user_deals_tableTarget.tabulator.setFilter(filters);
      this.country_deals_tableTarget.tabulator.setFilter(filters);
      this.partner_deals_tableTarget.tabulator.setFilter(filters);
    }
  }

  async createPartnerDealsTable(url) {
    const columns = this.partner_deals_tableTarget.getAttribute("data-table-columns");
    const table_target = this.partner_deals_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_report_partner_deals_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  async createCountryDealsTable(url) {
    const columns = this.country_deals_tableTarget.getAttribute("data-table-columns");
    const table_target = this.country_deals_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_report_country_deals_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  async createUserDealsTable(url) {
    const columns = this.user_deals_tableTarget.getAttribute("data-table-columns");
    const table_target = this.user_deals_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_report_user_deals_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createLostDealsTable(url) {
    const table_target = this.lost_deals_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_report_lost_deals_table",
      movableColumns: true,
      // columns
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("sales_report_lost_deals_table");
        table.tabulator.setColumns(response.table_columns);

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createDealsNewPartnersTable(url) {
    const columns = this.deals_new_partners_tableTarget.getAttribute("data-table-columns");
    const table_target = this.deals_new_partners_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_report_deals_new_partners_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createDealsPartnersTable(url) {
    const columns = this.deals_partners_tableTarget.getAttribute("data-table-columns");
    const table_target = this.deals_partners_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_report_deals_partners_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createDealsCountTable(url) {
    const table_target = this.deals_count_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_report_deals_count_table",
      movableColumns: true,
      // columns
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("sales_report_deals_count_table");
        table.tabulator.setColumns(response.table_columns);

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createDetailsTable(url) {
    const columns = this.details_tableTarget.getAttribute("data-table-columns");
    const table_target = this.details_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 220) + "px",
      persistenceID: "sales_report_details_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      rowFormatter: function (row) {
        const data = row.getData();
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }
}