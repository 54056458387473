import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";


Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "salesForecastReportTotalsText": function (values, data, calcParams) {
    const table = document.getElementById("sales_forecast_table")
    if (table)
      return table.getAttribute("data-total-text")
  },

  "salesForecastReportTotal": function (values, data, calcParams) {
    const table = document.getElementById("sales_forecast_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-" + calcParams["name"]);
    return total_html;
  }
});


export default class extends ApplicationController {
  static targets = ["report_period", "service_types", "countries", "invoices",
    "partners_statuses", "partners", "sales_directors", "products", "deal_statuses",
    "summary_expenses_table", "summary_incomes_table", "forecast_table"]


   connect() {
    // Main table
    if (this.hasSummary_expenses_tableTarget) {
      const content_url = this.summary_expenses_tableTarget.getAttribute("data-table-load-url");
      this.createSummaryExpensesTable(content_url);
    }

    if (this.hasSummary_incomes_tableTarget) {
      const content_url = this.summary_incomes_tableTarget.getAttribute("data-table-load-url");
      this.createSummaryIncomesTable(content_url);
    }

    if (this.hasForecast_tableTarget) {
      const content_url = this.forecast_tableTarget.getAttribute("data-table-load-url");
      this.createForecastTable(content_url);
    }
  }

  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let filters = []

    if (this.hasReport_periodTarget) {
      filters.push({
        field: "report_period",
        type: "=",
        value: this.report_periodTarget.value
      })
    }

    if (this.hasInvoicesTarget) {
      filters.push({
        field: "invoices_ids",
        type: "=",
        value: this.invoicesTarget.choices.getValue(true)
      })
    }

    if (this.hasService_typesTarget) {
      filters.push({
        field: "service_type_ids",
        type: "=",
        value: this.service_typesTarget.choices.getValue(true)
      })
    }

    if (this.hasCountriesTarget) {
      filters.push({
        field: "countries_ids",
        type: "=",
        value: this.countriesTarget.choices.getValue(true)
      })
    }

    if (this.hasPartners_statusesTarget) {
      filters.push({
        field: "partners_statuses",
        type: "=",
        value: this.partners_statusesTarget.choices.getValue(true)
      })
    }

    if (this.hasPartnersTarget) {
      filters.push({
        field: "partners_ids",
        type: "=",
        value: this.partnersTarget.choices.getValue(true)
      })
    }

    if (this.hasSales_directorsTarget) {
      filters.push({
        field: "sales_directors_ids",
        type: "=",
        value: this.sales_directorsTarget.choices.getValue(true)
      })
    }

    if (this.hasProductsTarget) {
      filters.push({
        field: "products_ids",
        type: "=",
        value: this.productsTarget.choices.getValue(true)
      })
    }

    if (this.hasDeal_statusesTarget) {
      filters.push({
        field: "deal_statuses",
        type: "=",
        value: this.deal_statusesTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      this.summary_expenses_tableTarget.tabulator.setFilter(filters);
      this.summary_incomes_tableTarget.tabulator.setFilter(filters);
      this.forecast_tableTarget.tabulator.setFilter(filters);
    }

  }


  async createSummaryExpensesTable(url) {
    const table_target = this.summary_expenses_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_forecast_summary_expenses_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      pagination: "remote",
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createSummaryIncomesTable(url) {
    const table_target = this.summary_incomes_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_forecast_summary_incomes_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      pagination: "remote",
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createForecastTable(url) {
    const table_target = this.forecast_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "sales_forecast_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("sales_forecast_table")

        for (const [key, value] of Object.entries(response.totals)) {
          table.setAttribute("data-" + key, value);
        }

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }
}