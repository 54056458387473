import { ApplicationController } from "../application_controller";
import BSN from "bootstrap.native"

export default class extends ApplicationController {
  static targets = ["creditors_report_period",
    "creditors_sales_directors",
    "creditors_suppliers", "creditors_countries",
    "creditors_deal_statuses",
    "debtors_report_period", "debtors_sales_directors",
    "debtors_partners", "debtors_accounts",
    "debtors_deal_statuses",
    "creditors_tables", "debtors_tables"
  ]

  connect() {

  }

  creditorsFilterTable() {
    let filters = {};

    if (this.hasCreditors_report_periodTarget) {
      filters["creditors_report_period"] =  this.creditors_report_periodTarget.value;
    }

    if (this.hasCreditors_sales_directorsTarget) {
      filters["creditors_sales_directors"] =  this.creditors_sales_directorsTarget.choices.getValue(true);
    }

    if (this.hasCreditors_suppliersTarget) {
      filters["creditors_suppliers"] = this.creditors_suppliersTarget.choices.getValue(true);
    }

    if (this.hasCreditors_countriesTarget) {
      filters["creditors_countries"] = this.creditors_countriesTarget.choices.getValue(true);
    }

    if (this.hasCreditors_deal_statusesTarget) {
      filters["creditors_deal_statuses"] = this.creditors_deal_statusesTarget.choices.getValue(true);
    }

    if (this.hasCreditors_tablesTarget) {
      this.updateContent(filters, this.creditors_tablesTarget, "creditors")
    }
  }

  debtorsFilterTable() {
    let filters = {};

    if (this.hasDebtors_report_periodTarget) {
      filters["debtors_report_period"] =  this.debtors_report_periodTarget.value;
    }

    if (this.hasDebtors_sales_directorsTarget) {
      filters["debtors_sales_directors"] =  this.debtors_sales_directorsTarget.choices.getValue(true);
    }

    if (this.hasDebtors_partnersTarget) {
      filters["debtors_partners"] = this.debtors_partnersTarget.choices.getValue(true);
    }

    if (this.hasDebtors_accountsTarget) {
      filters["debtors_accounts"] = this.debtors_accountsTarget.choices.getValue(true);
    }

    if (this.hasDebtors_deal_statusesTarget) {
      filters["debtors_deal_statuses"] = this.debtors_deal_statusesTarget.choices.getValue(true);
    }

    if (this.hasDebtors_tablesTarget) {
      this.updateContent(filters, this.debtors_tablesTarget, "debtors")
    }
  }

  clearCreditorsFilters(){
    if (this.hasCreditors_report_periodTarget) {
      this.creditors_report_periodTarget.flatpickr.setDate(this.creditors_report_periodTarget.dataset.period.split("|"));
    }

    if (this.hasCreditors_sales_directorsTarget) {
      this.creditors_sales_directorsTarget.choices.removeActiveItems();
    }

    if (this.hasCreditors_suppliersTarget) {
      this.creditors_suppliersTarget.choices.removeActiveItems();
    }

    if (this.hasCreditors_countriesTarget) {
      this.creditors_countriesTarget.choices.removeActiveItems();
    }

    if (this.hasCreditors_deal_statusesTarget) {
      this.creditors_deal_statusesTarget.choices.removeActiveItems();
    }

    if (this.hasCreditors_tablesTarget) {
      this.updateContent({}, this.creditors_tablesTarget, "creditors")
    }
  }

  clearDebtorsFilters(){
    if (this.hasDebtors_report_periodTarget) {
      this.debtors_report_periodTarget.flatpickr.setDate(this.debtors_report_periodTarget.dataset.period.split("|"));
    }

    if (this.hasDebtors_sales_directorsTarget) {
      this.debtors_sales_directorsTarget.choices.removeActiveItems();
    }

    if (this.hasDebtors_partnersTarget) {
      this.debtors_partnersTarget.choices.removeActiveItems();
    }

    if (this.hasDebtors_accountsTarget) {
      this.debtors_accountsTarget.choices.removeActiveItems();
    }

    if (this.hasDebtors_deal_statusesTarget) {
      this.debtors_deal_statusesTarget.choices.removeActiveItems();
    }

    if (this.hasDebtors_tablesTarget) {
      this.updateContent({}, this.debtors_tablesTarget, "debtors")
    }
  }

  collapseSection(event) {
    event.preventDefault();

    let target = event.currentTarget;
    if (!target.collapse) target.collapse = new BSN.Collapse(target);

    if (target.classList.contains("collapsed")) target.collapse.show();
    else target.collapse.hide();
  }

  collapseAll(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let accordion_target = document.querySelector(target.getAttribute("data-target"));
    let hide_elements = target.classList.contains("show-all");

    target.classList.toggle("show-all");
    accordion_target.classList.toggle("show-all");

    if (hide_elements) {
      accordion_target.querySelectorAll(".accordion__item__header:not(.collapsed)").forEach(e => {
        this.collapseClose(e)
      })
    } else {
      accordion_target.querySelectorAll(".accordion__item__header.collapsed").forEach(e => {
        this.collapseOpen(e)
      })
    }
  }

  collapseOpen(target) {
    if (!target.collapse) target.collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed")) target.collapse.show();
  }

  collapseClose(target) {
    if (!target.collapse) target.collapse = new BSN.Collapse(target)
    if (!target.classList.contains("collapsed")) target.collapse.hide();
  }


  updateContent(filters, target, table_type) {
    let url = target.getAttribute("data-tables-url");
    let url_params = new URLSearchParams(filters);
    url_params.append("table_type", table_type);


    fetch(url + "?" + url_params, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          target.innerHTML = JSON.parse(content)["page_content"];
        })
      }
    });
  }

}