import { ApplicationController } from "../application_controller";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BSN from "bootstrap.native"
import AutoNumeric from 'autonumeric';



export default class extends ApplicationController {
  static targets = ["report_period", "sales_directors",
    "countries", "partners", "partner_statuses", "top_limit",
    "deals_count_chart", "deals_partners_chart",
    "financial_result_chart", "users_deals_chart",
    "top_countries_deals_count_chart",
    "top_partners_deals_count_chart",
    "top_users_deals_count_chart",
    "top_countries_deals_amount_chart",
    "top_partners_deals_amount_chart",
    "top_users_deals_amount_chart",
    "top_countries_tourist_count_chart",
    "top_partners_tourist_count_chart",
    "top_users_tourist_count_chart",
    "top_countries_deals_profit_chart",
    "top_partners_deals_profit_chart",
    "top_users_deals_profit_chart"
  ]


  connect() {
    if (this.hasDeals_count_chartTarget) {
      this.createDealsCountChart(this.deals_count_chartTarget, "deals_count");
    }

    if (this.hasDeals_partners_chartTarget) {
      this.createDealsPartnersChart(this.deals_partners_chartTarget, "deals_partners");
    }

    if (this.hasFinancial_result_chartTarget) {
      this.createFinancialResultChart(this.financial_result_chartTarget, "financial_result");
    }

    if (this.hasUsers_deals_chartTarget) {
      this.createUsersDealsChart(this.users_deals_chartTarget, "users_deals");
    }

    /* TOP CHARTS */

    if (this.hasTop_countries_deals_count_chartTarget) {
      this.createTopChart(this.top_countries_deals_count_chartTarget, "top_countries_deals_count");
    }
    if (this.hasTop_partners_deals_count_chartTarget) {
      this.createTopChart(this.top_partners_deals_count_chartTarget, "top_partners_deals_count");
    }
    if (this.hasTop_users_deals_count_chartTarget) {
      this.createTopChart(this.top_users_deals_count_chartTarget, "top_users_deals_count");
    }
    if (this.hasTop_countries_deals_amount_chartTarget) {
      this.createTopChart(this.top_countries_deals_amount_chartTarget, "top_countries_deals_amount");
    }
    if (this.hasTop_partners_deals_amount_chartTarget) {
      this.createTopChart(this.top_partners_deals_amount_chartTarget, "top_partners_deals_amount");
    }
    if (this.hasTop_users_deals_amount_chartTarget) {
      this.createTopChart(this.top_users_deals_amount_chartTarget, "top_users_deals_amount");
    }
    if (this.hasTop_countries_tourist_count_chartTarget) {
      this.createTopChart(this.top_countries_tourist_count_chartTarget, "top_countries_tourist_count");
    }
    if (this.hasTop_partners_tourist_count_chartTarget) {
      this.createTopChart(this.top_partners_tourist_count_chartTarget, "top_partners_tourist_count");
    }
    if (this.hasTop_users_tourist_count_chartTarget) {
      this.createTopChart(this.top_users_tourist_count_chartTarget, "top_users_tourist_count");
    }
    if (this.hasTop_countries_deals_profit_chartTarget) {
      this.createTopChart(this.top_countries_deals_profit_chartTarget, "top_countries_deals_profit");
    }
    if (this.hasTop_partners_deals_profit_chartTarget) {
      this.createTopChart(this.top_partners_deals_profit_chartTarget, "top_partners_deals_profit");
    }
    if (this.hasTop_users_deals_profit_chartTarget) {
      this.createTopChart(this.top_users_deals_profit_chartTarget, "top_users_deals_profit");
    }
  }

  filterTable() {
    let filters = {};

    if (this.hasReport_periodTarget) {
      filters["report_period"] = this.report_periodTarget.value;
    }

    if (this.hasSales_directorsTarget) {
      filters["sales_directors"] = this.sales_directorsTarget.choices.getValue(true);
    }

    if (this.hasPartnersTarget) {
      filters["partners"] = this.partnersTarget.choices.getValue(true);
    }

    if (this.hasCountriesTarget) {
      filters["countries"] = this.countriesTarget.choices.getValue(true);
    }

    if (this.hasPartner_statusesTarget) {
      filters["partner_statuses"] = this.partner_statusesTarget.choices.getValue(true);
    }

    if (this.hasTop_limitTarget) {
      filters["top_limit"] = this.top_limitTarget.choices.getValue(true);
    }

    this.setFilters(filters)
  }

  clearFilters(){
    if (this.hasReport_periodTarget) {
      this.report_periodTarget.flatpickr.setDate(this.report_periodTarget.dataset.period.split("|"));
    }

    if (this.hasSales_directorsTarget) {
      this.sales_directorsTarget.choices.removeActiveItems();
    }

    if (this.hasPartnersTarget) {
      this.partnersTarget.choices.removeActiveItems();
    }

    if (this.hasCountriesTarget) {
      this.countriesTarget.choices.removeActiveItems();
    }

    if (this.hasPartner_statusesTarget) {
      this.partner_statusesTarget.choices.removeActiveItems();
    }

    if (this.hasTop_limitTarget) {
      this.top_limitTarget.choices.setChoiceByValue(this.top_limitTarget.dataset.selected);
    }

    this.setFilters({})
  }

  tabReloadEvent(event){
    this.filterTable();
  }

  setFilters(filters){
    if (this.hasDeals_count_chartTarget) {
      this.updateChart(filters, this.deals_count_chartTarget, "deals_count")
    }
    if (this.hasDeals_partners_chartTarget) {
      this.updateChart(filters, this.deals_partners_chartTarget, "deals_partners");
    }
    if (this.hasFinancial_result_chartTarget) {
      this.updateChart(filters, this.financial_result_chartTarget, "financial_result");
    }
    if (this.hasUsers_deals_chartTarget) {
      this.updateChart(filters, this.users_deals_chartTarget, "users_deals", 80);
    }

    /* TOP CHARTS */

    if (this.hasTop_countries_deals_count_chartTarget) {
      this.updateChart(filters, this.top_countries_deals_count_chartTarget, "top_countries_deals_count");
    }
    if (this.hasTop_partners_deals_count_chartTarget) {
      this.updateChart(filters, this.top_partners_deals_count_chartTarget, "top_partners_deals_count");
    }
    if (this.hasTop_users_deals_count_chartTarget) {
      this.updateChart(filters, this.top_users_deals_count_chartTarget, "top_users_deals_count");
    }
    if (this.hasTop_countries_deals_amount_chartTarget) {
      this.updateChart(filters, this.top_countries_deals_amount_chartTarget, "top_countries_deals_amount");
    }
    if (this.hasTop_partners_deals_amount_chartTarget) {
      this.updateChart(filters, this.top_partners_deals_amount_chartTarget, "top_partners_deals_amount");
    }
    if (this.hasTop_users_deals_amount_chartTarget) {
      this.updateChart(filters, this.top_users_deals_amount_chartTarget, "top_users_deals_amount");
    }
    if (this.hasTop_countries_tourist_count_chartTarget) {
      this.updateChart(filters, this.top_countries_tourist_count_chartTarget, "top_countries_tourist_count");
    }
    if (this.hasTop_partners_tourist_count_chartTarget) {
      this.updateChart(filters, this.top_partners_tourist_count_chartTarget, "top_partners_tourist_count");
    }
    if (this.hasTop_users_tourist_count_chartTarget) {
      this.updateChart(filters, this.top_users_tourist_count_chartTarget, "top_users_tourist_count");
    }
    if (this.hasTop_countries_deals_profit_chartTarget) {
      this.updateChart(filters, this.top_countries_deals_profit_chartTarget, "top_countries_deals_profit");
    }
    if (this.hasTop_partners_deals_profit_chartTarget) {
      this.updateChart(filters, this.top_partners_deals_profit_chartTarget, "top_partners_deals_profit");
    }
    if (this.hasTop_users_deals_profit_chartTarget) {
      this.updateChart(filters, this.top_users_deals_profit_chartTarget, "top_users_deals_profit");
    }
  }


  async createTopChart(target, table_type) {
     new Chart( target, {
      type: 'bar',
      data: {},
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maxBarThickness: 25,
        interaction: {
          intersect: false,
          axis: 'y',
          mode: 'index',
        },
        aspectRatio: 1,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: function(context) {
                return context[0].label.split(";")[0]
              }
            }
          },
          datalabels: {
            formatter: (value, ctx) => {
              return this.setFormat(value);
            },
            align: 'end',
            anchor: 'start',
            padding: 8,
            font: {
              size: 16,
              weight: 'bold'
            },
            color: "rgba(0, 0, 0, 1)"
          }
        },
        scales:{
          x: {
            display: false
          },
          y: {
            type: 'category',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              callback: function(val){
                return this.getLabelForValue(val).split(";")[0];
              }
            }
          },
          y1: {
            type: 'category',
            position: 'right',
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
            },
            ticks: {
              font: {
                size: 14
              },
              callback: function(val){
                let v = this.getLabelForValue(val).split(";")[1];
                if (v > 0) {
                  v += "% ↗";
                } else if (v < 0) {
                  v += "% ↘";
                } else {
                  v += "%"
                }

                return v;
              },
              color: function(v) {
                if ( parseInt(v.tick.label) > 0 ) {
                  return 'rgba(4, 183, 138, 1)';
                } else if ( parseInt(v.tick.label) < 0 ) {
                  return 'rgba(250, 80, 135, 1)';
                } else {
                  return 'rgba(86, 170, 255, 1)';
                }
              }
            }
          }
        }
      }
    });

    this.updateChart({}, target, table_type)
  }

  async createDealsCountChart(target, table_type) {
     new Chart( target, {
      data: {},
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'center'
          }
        },
        scales: {
          y: {
            min: 0
          }
        }
      }
    });

    this.updateChart({}, target, table_type)
  }

  async createDealsPartnersChart(target, table_type) {
    new Chart( target, {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {},
      options: {
        responsive: true,
        scaleBeginAtZero: true,
        plugins: {
          legend: {
            position: 'left',
          },
          tooltip: {
            yAlign: 'bottom',
            titleMarginBottom:0,
            callbacks: {
              title: function(context) {
                return context[0].label;
              },
              label: function(context) {
                return '';
              }
            }
          },
          datalabels: {
            formatter: (value, ctx) => {
              return value+"%";
            },
            color: 'rgba(255, 255, 255, 1)',
            font: {
              size: "20",
              weight: 'bold'
            }
          }
        }
      }
    });

    this.updateChart({}, target, table_type)
  }

  async createFinancialResultChart(target, table_type) {
     new Chart( target, {
      data: {},
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'center'
          }
        }
      }
    });

    this.updateChart({}, target, table_type)
  }

  async createUsersDealsChart(target, table_type){
    new Chart (target, {
      type: 'bar',
      plugins: [
        ChartDataLabels,
        {
        beforeDraw: function(chart) {
          let ctx = chart.ctx;
          ctx.save();

          let categoriesSize = chart.data.categories.length;
          let xAxis = chart.scales['x'];
          let delta = (xAxis.left + xAxis.right) / categoriesSize;
          let yBottom = chart.scales['y'].bottom;

          ctx.textAlign = 'center';
          ctx.font = '14px';
          ctx.strokeStyle  = 'lightgray';

          if ( categoriesSize > 0 ) {
            chart.data.categories.forEach((element, index) => {
              ctx.fillText(element, (xAxis.left + (delta*index) + delta/2), yBottom + 100);

              ctx.beginPath();
              ctx.moveTo(xAxis.left + (delta*(index+1)), yBottom);
              ctx.lineTo(xAxis.left + (delta*(index+1)), yBottom + 100);
              ctx.stroke();
            });

            ctx.beginPath();
            ctx.moveTo(xAxis.left, yBottom);
            ctx.lineTo(xAxis.left, yBottom + 100);
            ctx.stroke();
          }
          ctx.restore();
        }}
      ],
      data: {
        categories: []
      },
      options: {
        responsive: true,
        maxBarThickness: 40,
        // barPercentage: 0.4,
        categoryPercentage: 0.7,
        minBarLength: 10,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: function(context) {
                return context[0].label.replace(';', ' - ');
              }
            }
          },
          datalabels: {
            formatter: (value, context) => {
              return context.dataset.label + ':   ' +  this.setFormat(value) ;
            },
            clamp: true,
            clip: true,
            display: 'false',
            align: 'top',
            anchor: 'start',
            borderRadius: 4,
            color: 'rgb(0,0,0)',
            rotation: -90,
            padding: 6,
            font: {
              // weight: 'bold',
              size: 13
            }
          }
        },
        layout: {
          padding: {
            bottom: 25
          }
        },
        scales:{
          x: {
              type: "category",
              grid: {
                drawOnChartArea: false,
              },
              ticks: {
                maxRotation: 90,
                minRotation: 90,
                callback: function(val, index){
                  return this.getLabelForValue(val).split(";")[1];
                }
              }
          },
          y: {
            display: false
          }
        }
      },
    });

    this.updateChart({}, target, table_type, 80)
  }

  async updateChart(filters, target, table_type, chart_tick_size = 0) {
    let url = target.getAttribute("data-table-url");
    let url_params = new URLSearchParams(filters);
    let chart = Chart.getChart(target.id);

    url_params.append("table_type", table_type);

    fetch(url + "?" + url_params, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          chart.data    = JSON.parse(content)["data"];
          chart.update();

          if (chart_tick_size > 0){
            target.parentElement.style.width = Math.max(chart.data.labels.length * chart_tick_size, target.parentElement.parentElement.clientWidth) +'px';
          }

        })
      }
    });
  }

  setFormat(value) {
    if (!value)
      return 0;
    return AutoNumeric.format(value, {
      allowDecimalPadding: "floats",
      decimalPlaces: 2,
      digitGroupSeparator: " ",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      styleRules: {
        positive: "autoNumeric-positive"
      },
      minimumValue: 0
    });
  }
}